<ion-item-sliding #slidingItem (contextmenu)="onContextmenu($event)" (ionDrag)="interaction.emit()">
  <ion-item-options side="start">
    <ion-item-option color="primary" icon-only (click)="slidingItem.close(); edit.emit(session)">
      <ion-icon name="create-outline"></ion-icon>
    </ion-item-option>

    <ion-item-option color="secondary" icon-only (click)="slidingItem.close(); save.emit(session)">
      <ion-icon name="share-outline"></ion-icon>
    </ion-item-option>

  </ion-item-options>

  <ion-item-options side="end">
    <ion-item-option color="tertiary" icon-only (click)="slidingItem.close(); reset.emit(session)">
      <ion-icon name="refresh-outline"></ion-icon>
    </ion-item-option>

    <ion-item-option color="danger" icon-only (click)="slidingItem.close(); delete.emit(session)">
      <ion-icon name="trash-outline"></ion-icon>
    </ion-item-option>

  </ion-item-options>

  <ion-item [color]="color" (click)="selectItem.emit()">
    <ion-label [class.in-item-color]="selected">
      <h2>{{session?.name}}</h2>
      <p>{{device}}, {{session?.ram}}MB RAM<span *ngIf="session?.osVersion">, OS
          {{decodeVersion(session?.osVersion)}}</span>
      </p>
    </ion-label>
  </ion-item>
</ion-item-sliding>
