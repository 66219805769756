<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title>About</ion-title>

    <ion-buttons slot="secondary">
      <app-pwa-prompt></app-pwa-prompt>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">About</ion-title>
    </ion-toolbar>
  </ion-header>

  <div class="loader" *ngIf="loading">
    <ion-spinner></ion-spinner>
  </div>

  <div class="markdown-container">
    <markdown src="assets/doc/about.md" (load)="loading = false"></markdown>
  </div>

  <div class="version">
    CloudpilotEmu version {{version}}
    <br />
    <a href='javascript:' (click)="showChangelog()">Changelog</a>
    <div *ngIf="serviceWorkerAvailable">app cached for offline use</div>
  </div>

  <img class="logo" src="assets/logo.svg">

</ion-content>
