<ion-header translucent>
  <ion-toolbar>
    <ion-title>Authenticate</ion-title>

    <ion-buttons slot="primary">
      <ion-button (click)="saveAndContinue()">OK</ion-button>
    </ion-buttons>

    <ion-buttons slot="secondary">
      <ion-button (click)="onCancel()">Cancel</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content fullscreen>
  <form [formGroup]="formGroup" (keydown)="formKeyDown($event)">
    <p>
      The server requires authentication.
    </p>

    <ion-item lines="inset">
      <ion-label position="stacked">Username</ion-label>
      <ion-input placeholder="Enter username" formControlName="username"></ion-input>
    </ion-item>

    <ion-item lines="inset">
      <ion-label position="stacked">Password</ion-label>
      <ion-input placeholder="Enter password" formControlName="password" type="password"></ion-input>
    </ion-item>
  </form>
</ion-content>
