<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title>Settings</ion-title>

    <ion-buttons slot="secondary">
      <ion-button (click)="showHelp()">
        <ion-icon name="help-outline"></ion-icon>
      </ion-button>

      <app-pwa-prompt></app-pwa-prompt>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">Settings</ion-title>
    </ion-toolbar>
  </ion-header>

  <div class="settings-container">
    <form [formGroup]="formGroup" class="settings-form">
      <h1>Emulation</h1>

      <ion-item lines="none">
        <ion-label>Volume</ion-label>
        <ion-range min="0" max="1" step="0.1" formControlName="volume"></ion-range>
      </ion-item>

      <ion-item lines="none">
        <ion-label>Enable audio on start</ion-label>
        <ion-toggle formControlName="audioOnStart"></ion-toggle>
      </ion-item>

      <ion-item lines="none">
        <ion-label>Run when hidden</ion-label>
        <ion-toggle formControlName="runHidden"></ion-toggle>
      </ion-item>

      <h1>Host integration</h1>

      <ion-item lines="none" *ngIf="clipboardService.isSupported()">
        <ion-label>Clipboard integration</ion-label>
        <ion-toggle formControlName="clipboardIntegration"></ion-toggle>
      </ion-item>

      <ion-item lines="none">
        <ion-label>Network redirection</ion-label>
        <ion-toggle formControlName="networkRedirection"></ion-toggle>
      </ion-item>

      <ion-item lines="none" *ngIf="showProxyServer">
        <ion-label position="stacked">Proxy server</ion-label>
        <div class="server-container">
          <ion-input placeholder="Proxy server host or URL" formControlName="proxyServer"></ion-input>
          <ion-button [disabled]="!proxyServerValid"
            (click)="$event.preventDefault(); $event.stopPropagation(); testProxyConnection()"
            (touchstart)="$event.preventDefault(); $event.stopPropagation(); testProxyConnection()">
            Test
          </ion-button>
        </div>
      </ion-item>

      <h1>UI</h1>

      <ion-item lines="none">
        <ion-label>Auto-lock UI</ion-label>
        <ion-toggle formControlName="autoLockUI"></ion-toggle>
      </ion-item>

      <ion-item lines="none">
        <ion-label>Show statistics</ion-label>
        <ion-toggle formControlName="showStatistics"></ion-toggle>
      </ion-item>

      <ion-item lines="none">
        <ion-label>Remote installation sources</ion-label>
        <ion-toggle formControlName="enableRemoteInstall"></ion-toggle>
      </ion-item>

    </form>
  </div>

</ion-content>
