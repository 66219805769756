<ion-list>
  <ion-item button [detail]="false" (click)="onEdit()">
    Edit
  </ion-item>
  <ion-item button [detail]="false" (click)="onSave()">
    Save
  </ion-item>
  <ion-item button [detail]="false" (click)="onReset()">
    Reset
  </ion-item>
  <ion-item button [detail]="false" (click)="onDelete()">
    Delete
  </ion-item>
</ion-list>
