export enum DeviceId {
    palmPilot = 'PalmPilot',
    pilot = 'Pilot',
    iii = 'PalmIII',
    palmVx = 'PalmVx',
    palmV = 'PalmV',
    palmVII = 'PalmVII',
    palmVIIEZ = 'PalmVIIEZ',
    palmVIIx = 'PalmVIIx',
    iiic = 'PalmIIIc',
    iiix = 'PalmIIIx',
    iiixe = 'PalmIIIxe',
    iiie = 'PalmIIIe',
    m500 = 'PalmM500',
    m505 = 'PalmM505',
    m515 = 'PalmM515',
    m100 = 'PalmM100',
    m105 = 'PalmM105',
    m125 = 'PalmM125',
    m130 = 'PalmM130',
    i705 = 'Palmi705',
    i710 = 'PalmI710',
    handera330 = 'HandEra330',
    pegS300 = 'PEG-S300',
    pegS320 = 'PEG-S320',
    pegS500c = 'PEG-S500C',
    pegT400 = 'PEG-T400',
    pegN600c = 'PEG-N600C/N610C',
    pegT600c = 'PEG-T600',
    pegN700c = 'PEG-N700C/N710C',
    pegT650c = 'YSX1230',
    pegNR70 = 'NR70',
}
